<template>
  <tr
    :class="{
      'border-none': stok.isDuplicate,
    }"
  >
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center" style="text-wrap: wrap">
        {{ stok?.instance?.name }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center" style="text-wrap: wrap">
        {{ !stok.isDuplicate ? stok.productName : "" }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{
          !stok.isDuplicate ? (stok.product ? stok.product.packaging : "") : ""
        }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{
          !stok.isDuplicate
            ? stok.product
              ? stok.product.unit
                ? stok.product.unit.name
                : ""
              : ""
            : ""
        }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        <span v-for="(category, index) in stok.product.categories" :key="index">
          {{ category.name ? category.name : "Data Error" }}</span
        >
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ stok.product ? stok.product.group : "" }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ stok.batchNo ? stok.batchNo : "" }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ convertNumberToCurrency(stok.price) }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ stok.fundsName }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ formatNumber(stok.quantity) }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div class="text-center">
        {{ "" }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div
        class="text-center"
        :class="{
          'text-red-500': checkExpiryStatus(stok.expiry, 0) == 'Expired',
        }"
      >
        {{ stok.expiry }}
      </div>
    </td>

    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <router-link
        :to="{
          name: 'KartuStok',
          params: { id: stok.product.id },
          query: { batchNo: stok.batchNo },
        }"
        class="p-1.5 px-3 rounded bg-emerald-500 text-white hover:opacity-70"
        >Kartu Stok</router-link
      >
    </td>
  </tr>
</template>

<script>
import Swal from "sweetalert2";
import SwallToast from "../../helpers/swall-toast";
import {
  checkExpiryStatus,
  convertNumberToCurrency,
  formatNumber,
} from "../../utils/Utils";

export default {
  name: "StokTableItem",
  props: ["stok", "value", "selected", "index"],
  setup(props, context) {
    return {
      convertNumberToCurrency,
      formatNumber,
      checkExpiryStatus,
    };
  },
};
</script>
